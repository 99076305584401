<template>
	<section class="domain_content">
		<!-- About Us -->
		<div class="sub-page">
			<h1>About Us</h1>
			<p>
        		Welcome to <a href="https://whatsapppro.org/">https://whatsapppro.org/</a>. We provide secure and reliable platform for you to enhance your messaging experience. By using the app, you can enjoy custom features and set privacy features. That 's good to build a harmonious world, strengthening communication between people across the world.
			</p>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'About Us'
const description = 'We aim to ensure that you have a comprehensive understanding of our services, allowing you to use the site with confidence and peace of mind.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
            "link": [{
                "rel": "canonical",
                "href": "https://whatsapppro.com/about-us/"
            }]
	},
}
</script>
  